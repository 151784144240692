import { useParams } from "react-router";
import "../App.css";
import blogJson from "../blogs.json";

const Blog = (props) => {
  let params = useParams();
  console.log(params.id);
  const blogs_data = blogJson.blogs;
  const blog = blogs_data[params.id];
  return (
    <div className="page">
      <div id="snackbar">Coming Soon...</div>
      <div className="section">
        <div className="blog-page">
          <h2 className="blog-title">{blog.title}</h2>
          {blog.content.split(".").map((value) =>
            <p>{value}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
