import React from "react";
import { createRoot, hydrateRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import Nav from "./Nav/Nav";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blog from "./Blog/Blog";

const renderElement = (
  <BrowserRouter>
    <Nav />
    <Routes>
      <Route path="/blog/:id" Component={Blog}></Route>
      <Route path="/endeavor" element={<App />}></Route>
      <Route path="/*" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(document.getElementById("root"), renderElement);
} else {
  const root = createRoot(document.getElementById("root"));
  root.render(renderElement);
}

function sendToAnalytics({ name, id, delta, value, attribution }) {
  const eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
  };

  switch (name) {
    case 'CLS':
      eventParams.debug_target = attribution?.largestShiftTarget;
      break;
    case 'FID':
      eventParams.debug_target = attribution?.eventTarget;
      break;
    case 'INP':
      eventParams.debug_target = attribution?.eventTarget;
      eventParams.inp_target = attribution?.eventTarget;
      eventParams.inp_type = attribution?.eventType;
      eventParams.inp_time = attribution?.eventTime;
      eventParams.inp_load_state = attribution?.loadState;
      break;
    case 'LCP':
      eventParams.debug_target = attribution?.element;
      break;
    default:
      eventParams.debug_target = attribution?.element;
      break;
  }
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  window?.gtag && window.gtag('event', name, eventParams);
}

reportWebVitals(sendToAnalytics);
