import "./App.css";
import blogJson from "./blogs.json";
import {useState, useEffect} from "react";

const STEAM_URL = "https://store.steampowered.com/app/3008810/Endeavor/";

const App = (props) => {
  const [gif, setGif] = useState("gif1.gif");
  const showSnackbar = () => {

    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";
    // After 3 seconds, remove the show class from DIV
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    setTimeout(function () {
      window.open(STEAM_URL, '_blank').focus();
    }, 500);
 
  }
  
  const blogs_data = blogJson.blogs;

  const timeout = setInterval(() => {
    let newGif = "gif1.gif";
    if (gif === "gif1.gif") {
      newGif = "gif2.gif"
    }
    else if (gif === "gif2.gif") {
      newGif = "gif3.gif"
    }
    setGif(newGif);
  }, 10000)

  useEffect(() => {
    return () => {
      clearInterval(timeout);
    }
  })

  return (
    <div className="page">
      <div id="snackbar">Add to Wishlist on Steam!</div>
      <div className="section">
        <img id="myVideo" className="hero" alt={gif} src={gif} />
      </div>
      <div className="section">
        <div className="play">
          <button onClick={() => showSnackbar()} >ENDEAVOR</button>
        </div>
      </div>
      <div className="section">
        <div className="blog">
          {blogs_data.map((data, index) => {
            return (
              <div className="blog-item" onClick={() => { window.location.assign(window.location.origin + "/blog/" + index) }}>
                <h2>{data.title}</h2>
                <p>{data.content}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default App;
